import React from 'react'

import storeContent from './mocks/storeJson.json'

const WarrantyContent = () => {
  const { image1, text1, image2, text2 } = storeContent.vivara

  return (
    <div className="warranty-content">
      <div className="warranty-block">
        <img src={image1} alt="" />
        <p className="warranty-description">{text1}</p>
      </div>
      <div className="warranty-block">
        <img src={image2} alt="" />
        <p className="warranty-description">{text2}</p>
      </div>
    </div>
  )
}

export default WarrantyContent
