import React from 'react'
import type { FC } from 'react'

interface CloseIconProps {
  color?: string
  strokeWidth?: string
  width?: string
  height?: string
  onClick?: () => void
  className?: string
}

const CloseIcon: FC<CloseIconProps> = ({
  color,
  strokeWidth = '1',
  width = '10',
  height = '10',
  onClick,
  className,
}) => (
  <svg
    data-close-icon
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={strokeWidth}
    onClick={onClick}
    aria-label="Close Icon"
    className={className}
  >
    <path
      d="M1 1L5.00092 5.00092L1 9"
      stroke={color ?? '#FFFFFF'}
      strokeMiterlimit="10"
    />
    <path
      d="M9.00122 9L5.00031 4.99908L9.00122 1"
      stroke={color ?? '#FFFFFF'}
      strokeMiterlimit="10"
    />
  </svg>
)

export default CloseIcon
