import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './styles.scss'
import DangerSvg from 'src/images/svg/Danger'
import Spinner from 'src/components/common/Spinner'

import storeConfig from '../../../../store.config'
import Banner from './Banner'
import TextAreaComp from './TextAreaComp'
import WarrantyContent from './WarrantyContent'
import { validateSession } from '../../../utils/validateSession'

export type PropsWarrantyComponent = {
  changeStep: React.Dispatch<React.SetStateAction<boolean>>
  warrantyError: boolean
  setWarrantyError?: React.Dispatch<React.SetStateAction<boolean>>
}

interface User {
  email: string
}

interface RedirectLogin {
  returnUrl?: string
}

export const redirectToLogin = ({ returnUrl }: RedirectLogin) => {
  if (!window) {
    return
  }

  if (!returnUrl) {
    const url = window?.location?.pathname

    window.location.href = `${storeConfig.loginUrl}?returnUrl=${url}`

    return
  }

  window.location.href = `${storeConfig.loginUrl}?returnUrl=${returnUrl}`
}

function WarrantyValidate({
  changeStep,
  warrantyError,
  setWarrantyError,
}: PropsWarrantyComponent) {
  const [user, setUser] = useState<User | null>()
  const [text, setText] = useState('')

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!user) {
      const returnSession = async () => {
        const response = await validateSession()

        setUser(response?.person)
      }

      returnSession()
    }
  }, [user])

  useEffect(() => {
    if (user === null) {
      const currentUrl = window.location.href
      const searchParams = new URLSearchParams(currentUrl?.split('?')[1])
      const warrantyId = searchParams?.get('warrantyId')
      const returnUrl = warrantyId
        ? `/garantia-de-presente/?warrantyId=${warrantyId}`
        : '/garantia-de-presente'

      redirectToLogin({ returnUrl })
    }

    setIsLoading(!user)
  }, [isLoading, user])

  const request = async () => {
    if (!user) {
      return
    }

    try {
      const response = await axios.post('/api/WarrantyImport', {
        codigo: text,
        email: user?.email,
      })

      if (response) {
        changeStep(true)
      }
    } catch (error) {
      if (setWarrantyError) {
        setWarrantyError(true)
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="warranty-loader">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="warranty-first">
            <h3>insira o código da garantia do seu presente</h3>
            <span>
              Tenha a garantia compartilhada e ganhe acesso rápido a todos os
              nossos benefícios
            </span>
            <div className="border" />
            <WarrantyContent />

            <div className="warranty-data">
              <div className="input-block">
                <TextAreaComp
                  warrantyError={warrantyError}
                  isLoading={isLoading}
                  stateActions={{
                    text,
                    setText,
                  }}
                />

                <div
                  className={`${!warrantyError ? '' : 'flex-content'}`}
                  hidden={!warrantyError}
                >
                  <DangerSvg />
                </div>
              </div>
              <button
                className="warranty-button-orange"
                onClick={() => {
                  request()
                }}
              >
                VALIDAR CÓDIGO
              </button>
            </div>
          </div>
          <Banner
            setWarrantyError={setWarrantyError}
            warrantyError={warrantyError}
          />
        </>
      )}
    </>
  )
}

export default WarrantyValidate
