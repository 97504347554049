import React from 'react'
import { Link } from 'gatsby'

import Banner from './Banner'

interface ChangeStepProps {
  changeStep: React.Dispatch<React.SetStateAction<boolean>>
}

function ConfirmedWarranty({ changeStep }: ChangeStepProps) {
  const handleClick = () => {
    changeStep(false)
  }

  return (
    <>
      <div className="warranty-first confirmed">
        <div className="warranty-block">
          <img
            src="https://qavivara.vteximg.com.br/arquivos/confirmed-warranty.png"
            alt=""
            className="confirmed-image"
          />
        </div>

        <h3>Garantia compartilhada com sucesso!</h3>
        <div className="border" />
        <Link
          to="/institucional/politica-garantia"
          className="warranty-button-gray"
        >
          Conferir política de garantia
        </Link>
        <Link
          to="/garantia-de-presente/validate"
          className="warranty-button-gray"
          onClick={handleClick}
        >
          Compartilhar nova garantia
        </Link>
        <Link to="/account#/profile" className="warranty-button-orange">
          ir para a minha conta
        </Link>
      </div>

      <Banner />
    </>
  )
}

export default ConfirmedWarranty
