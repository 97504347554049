import React, { useState } from 'react'

import ConfirmedWarranty from './ConfirmedWarranty'
import WarrantyValidate from './WarrantyValidate'

function WarrantyValidateComp() {
  const [confirmedWarranty, setIsConfirmed] = useState<boolean>(false)
  const [warrantyError, setWarrantyError] = useState<boolean>(false)

  return (
    <section className="WarrantyComponent">
      {confirmedWarranty ? (
        <ConfirmedWarranty changeStep={setIsConfirmed} />
      ) : (
        <WarrantyValidate
          changeStep={setIsConfirmed}
          warrantyError={warrantyError}
          setWarrantyError={setWarrantyError}
        />
      )}
    </section>
  )
}

export default WarrantyValidateComp
