import type { ChangeEvent } from 'react'
import React, { useState, useRef, useEffect } from 'react'

type InputTextProps = {
  warrantyError: boolean
  isLoading: boolean
  stateActions: {
    text: string
    setText: React.Dispatch<React.SetStateAction<string>>
  }
}

function TextAreaComp({
  warrantyError,
  isLoading,
  stateActions,
}: InputTextProps) {
  const { text, setText } = stateActions

  const [isMultiline, setIsMultiline] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
    setIsMultiline(event.target.scrollHeight > event.target.clientHeight)
  }

  useEffect(() => {
    const currentURL = window.location.href

    const parmString = currentURL.indexOf('?')

    const hasParmString = parmString !== -1

    if (!hasParmString) {
      return
    }

    const params = currentURL.slice(parmString + 1)
    const arrayOfParms = params.split('&')

    const warrantyIdParm = arrayOfParms.find((param) =>
      param.startsWith('warrantyId=')
    )

    if (warrantyIdParm) {
      const idValue = warrantyIdParm.split('=')

      setText(idValue[1])
    }
  }, [isLoading])

  useEffect(() => {
    const textarea = textareaRef.current

    if (!textarea) {
      return
    }

    textarea.style.height = 'auto'
    const { scrollHeight } = textarea

    textarea.style.height = `${Math.min(64, Math.max(48, scrollHeight))}px`
  }, [text])

  return (
    <div className="text-box centered">
      <textarea
        ref={textareaRef}
        rows={1}
        value={text}
        onChange={handleTextArea}
        className={`${!warrantyError ? '' : 'error'}`}
        style={{
          lineHeight: isMultiline ? '19.69px' : 'initial',
          padding: isMultiline ? '12px 32px 12px 20px' : '15px 32px 10px 20px',
        }}
      />
    </div>
  )
}

export default TextAreaComp
