import React, { useEffect, useState } from 'react'
import { Skeleton } from '@faststore/ui'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import CloseIcon from 'src/components/icons/Close'
import DangerSvg from 'src/images/svg/Danger'

type BannerProps = {
  setWarrantyError?: React.Dispatch<React.SetStateAction<boolean>>
  warrantyError?: boolean
}

const Banner = ({ setWarrantyError, warrantyError }: BannerProps) => {
  const { isMobile } = useWindowDimensions()

  const [banner, setBanner] = useState('')

  useEffect(() => {
    !isMobile ? setBanner('hero-1.png') : setBanner('hero-1-mobile.png')
  }, [banner, isMobile])

  return (
    <div className="warranty-second">
      <>
        <div
          className={`error-message ${!warrantyError ? '' : 'flex-content'}`}
        >
          <div>
            <DangerSvg />
          </div>
          <p>
            Ops! Algo deu errado. Por favor, tente inserir o código novamente.
          </p>
          <button
            className="close"
            onClick={() => {
              setWarrantyError?.(false)
            }}
          >
            <CloseIcon color="#000" height="12" />
          </button>
        </div>
      </>
      {banner !== '' ? (
        <img
          src={`https://qavivara.vteximg.com.br/arquivos/${banner}`}
          alt=""
        />
      ) : (
        <Skeleton />
      )}
    </div>
  )
}

export default Banner
